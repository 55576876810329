import { request, beacon } from './utils/request';
import { parseUrl, parseUrlParams, decorate } from './utils/url';
import { isDefined, isPlainObject } from './utils/helpers';
import { dispatchEvent } from './utils/events';

import { Session } from './session';
import { Visits } from './visits';
import { Forms } from './forms';
import { Reviews } from './reviews';
import { Personalization } from './personalization';
import { Calltracking } from './calltracking';
import { fingerprint } from './fingerprint';

import type { McfxConfigOptions, McfxModules, SessionResponse } from './declarations';

const ModuleMap = {
  visits: Visits,
  view: Visits,
  forms: Forms,
  reviews: Reviews,
  pfx: Personalization,
  calltracking: Calltracking,
};

export class Tracker {
  initializedAt: number;
  configuration: McfxConfigOptions;
  session: Session;
  modules: Partial<McfxModules>;

  constructor(_options: McfxConfigOptions | string | number, initializedAt: number) {
    const url = parseUrl();
    const params = parseUrlParams(url?.search);

    this.configuration = {
      status: 'active',
      mode: params?.mcfxDebug == '1' ? 'debug' : 'normal',
      ...(params?.mcfxMode && { mode: params.mcfxMode }),
      formSessionLength: 10,
      visitorSessionLength: 30,
      trackInputs: true,
      useSecureCookies: true,
      urlComparedBy: 'href',
      onFormCapture: (form) => form,
      onViewCallback: (data) => data,
      filterFormInputs: (formData, _form) => formData,
      modules: ['view', 'forms', 'reviews'],
      cookieDomain: url?.cookieDomain ?? window.location.hostname,
      agentUrl: `https://t.marketingcloudfx.com`,
      ...((isPlainObject(_options) ? _options : { siteId: _options }) as McfxConfigOptions),
    };
    this.initializedAt = initializedAt;
    this.session = new Session(this.configuration);
    this.modules = {};

    this.init();
  }

  /**
   * @deprecated
   */
  get options(): McfxConfigOptions {
    return this.configuration;
  }

  /**
   * method for backwards compatibility with v2
   * @deprecated
   */
  get visitor() {
    return {
      get: (key) => {
        const post = this.session.getPostValues();
        if (!key) {
          return post;
        }
        return post[key];
      },
      set: (key, value) => {
        this.session.legacySetVisitor(key, value);
      },
    };
  }

  /**
   * Initialize the tracker by setting up the session and configuring modules.
   * This method fetches session data from the server, configures the tracker
   * based on the session configuration, and enables the necessary modules.
   * It also dispatches various events to signal different stages of initialization.
   */
  async init() {
    let session: SessionResponse;
    const maxRetries = 3;
    let attempt = 0;

    while (attempt < maxRetries && !session?.configuration) {
      try {
        session = (await request(`${this.configuration.agentUrl}/session`).post({
          siteId: this.configuration.siteId,
          visitorId: this.session.get('uid'),
          sessionId: this.session.get('sid'),
          referer: this.session.get('r'),
          url: window.location.href,
        })) as SessionResponse;
      } catch (error) {
        attempt++;
        if (attempt >= maxRetries) {
          dispatchEvent('failed', this);
          console.warn('MCFX was unable to initialize session');
        }
      }
    }

    this.configure(session.configuration);

    if (!this.session.get('attribution')) {
      this.session.set('location', session.location);
      this.session.set('attribution', session.attribution);
    }

    dispatchEvent('pre-enable', this);

    if (session.configuration.status === 'inactive') {
      dispatchEvent('disabled', this);
      return;
    }

    if (!this.session.get('fingerprint')) {
      this.session.set('fingerprint', await fingerprint(this));
    }

    dispatchEvent('fingerprinted', this);

    this.enableModule(this.configuration.modules);
    dispatchEvent('initialized', this);
    dispatchEvent('loaded', this); // deprecate
  }

  /**
   * Send event information to MCFX
   * @param  {...any} args
   * @returns
   */
  async send(...args) {
    let dataToPost;
    const attrib = {
      o: window.location.origin,
      u: window.location.href,
      te: new Date().getTime(),
      ...this.session.getPostValues(),
      a: this.configuration.siteId,
    };

    if (isPlainObject(args[0])) {
      // allows prop name of hitType or type
      if (args[0].hitType) {
        args[0].type = args[0].hitType;
        delete args[0].hitType;
      }

      dataToPost = {
        type: 'event', // event | form
        category: null,
        action: null,
        label: null,
        value: null,
        ...args[0],
        attrib,
      };
    } else {
      dataToPost = {
        type: args[0] || 'event', // event | form
        category: args[1] || null,
        action: args[2] || null,
        label: args[3] || null,
        value: args[4] || null,
        attrib,
      };
    }

    beacon(`${this.configuration.agentUrl}/event`, dataToPost);
    dispatchEvent(`${dataToPost.type}:collect`, dataToPost);
    dispatchEvent(`collect`, dataToPost);
  }

  /**
   * Manual method to capture the current state of a form element
   * and send to MCFX for parsing
   * @param {HTMLNode} form
   */
  capture(form) {
    this.modules.forms?.capture(form);
  }

  /**
   * Set configuration properties for tracking
   * @param {*} prop
   * @param {*} value
   * @returns
   */
  configure(prop: string | Record<string, any>, value: any = null) {
    if (isPlainObject(prop)) {
      Object.keys(prop).forEach((k) => {
        this.configuration[k] = prop[k];
      });
      return;
    }

    this.configuration[prop as string] = value;
  }

  /**
   * Set information about the visitor
   * @param  {...any} args
   */
  set(key: string | Record<string, any>, value?: any) {
    this.session.set(key, value);
  }

  /**
   * get information about the visitor/session
   * @param  {...any} args
   */
  get(key?: string | string[]) {
    return this.session.get(key);
  }

  /**
   * enable specific module(s)
   * @param {string|array} mods
   */
  enableModule(mods: keyof McfxModules | (keyof McfxModules)[] = []) {
    if (!Array.isArray(mods)) {
      mods = [mods];
    }
    mods.forEach((m) => {
      if (ModuleMap[m] && !this.modules[m]) {
        this.modules[m] = new ModuleMap[m](this);
      }
    });
  }

  /**
   * disable specific module(s)
   * @param {string|array} mods
   */
  disableModule(mods: keyof McfxModules | (keyof McfxModules)[] = []): void {
    if (!Array.isArray(mods)) {
      mods = [mods];
    }
    mods.forEach((m) => {
      if (this.modules[m]) {
        this.modules[m]?.stop();
        delete this.modules[m];
      }
    });
  }

  /**
   * determine if a module is enabled
   * @param {string} mod
   * @returns {boolean}
   */
  isModuleEnabled(mod: string): boolean {
    return !!this.modules[mod];
  }

  /**
   * Decorates links on the page with session data.
   *
   * @param urls - The URLs to decorate.
   */
  decorate(urls: string | string[]) {
    return decorate(urls, this.session);
  }
}

declare module './declarations' {
  type MCFXTracker = Tracker;
}
